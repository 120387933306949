"use strict";

angular.module("informaApp")
    .service("ConstantsSvc", ["$location", function ($location) {
        function getDomainUrl() {
            return $location.$$protocol + '://' + $location.$$host + ($location.$$port ? ':' + $location.$$port : '') + '/';
        }

        const result = {
            auth: {
                cookiesExpirationHours: 8,
                tokenCookieName: 'token'
            },
            biomedtracker: {
                baseUrl: window.envVariables.biomedtracker.baseUrl,
                others: {
                    eventHistory: {
                        url: "DrugDetail.cfm",
                        paramName: "RecID"
                    },
                    indication: {
                        url: "IndicationReport.cfm",
                        paramName: "IndID"
                    },
                    company: {
                        url: "CompanyReport.cfm",
                        paramName: "CompanyID"
                    },
                    target: {
                        url: "targetReport.cfm",
                        paramName: "TargetID"
                    },
                    drug: {
                        url: "DrugReport.cfm",
                        paramName: "DrugID"
                    },
                    login: {
                        url: "login.cfm"
                    }
                }
            },
            DOMAIN: getDomainUrl(),
            API: {
                URL: '/api/',
            },
            Dates: {
                start: new Date('2000/01/01'),
                end: new Date(new Date().getFullYear() + '/12/31'),
                queryFormat: "YYYY-MM-DD",
                fullAngularFormat: "dd MMMM yyyy",
                fullMomentFormat: 'DD MMMM YYYY',
                monthAndYearDateFormat: "MMMM yyyy"
            },
            DateTime: {
                fullAngularFormat: "dd MMMM yyyy HH:mm"
            },
            COOKIE_NAMES: {
                RestrictSystemNotification: 'restrict_system_notification'
            },
            EVENTS: {
                SearchSaved: "Search has been saved",
                LoginSuccess: "Successfully logged in!",
                RedirectToLogoutBy403: "403-redirect-to-logout",
                SystemNotification: {
                    SaveSuccess: 'System notification has been saved successfully',
                    SaveError: 'Error saving system notification',
                    DeleteSuccess: 'System notification has been removed successfully',
                    DeleteError: 'Error removing system notification'
                }
            },
            NOTIFICATIONS: {
                MessageDuration: 3500
            },
            MIME_TYPES: {
                XLSX: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            },
            Phases: ["Phase I", "Phase II", "Phase III", "NDA BLA"],
            phasePropertyNames: ['phase1', 'phase2', 'phase3', 'ndabla'],
            tableGroupsName: {
                allData: {name: "All Data", alternative: "All Data"},
                diseaseGroup: {name: "Disease Group", alternative: "Disease Group"},
                subDiseaseGroup: {name: "Sub Disease Group", alternative: "Sub Disease Group"},
                indication: {name: "Indications", alternative: "Indication Name"},
                company: {name: "Company", alternative: "Lead Company"},
                drugClass: {name: "Drug Class", alternative: "Drug Classification"},

                molecule: {name: 'Molecule', alternative: 'Molecule'},
                target: {name: 'Target', alternative: 'Target'},
                drug: {name: 'Drug', alternative: 'Drug'},
                route: {name: 'Route', alternative: 'Route'}
            },
            urls: {
                chart: "/chart",
                trendsView: '/trends-view',
                table: "/table",
                predictive: "/predictive",
                companyProfile: "/company-profile",
                ganttChart: "/gantt-chart",
                comparison: "/comparison",
                login: "/login",
                admin: "/admin",
                summaryView: '/summary',
                newFeatures: '/new-features',
                emailVerification: '/email-verification',
                forbidden: '/forbidden',
                logout: '/logout',
                logoutRedirect: '/logout/redirect',
                internalError: '/error',
                cookiePolicy: '/cookie-policy',
                cookieList: '/cookie-list'
            },
            phaseColors: ['#EECA2F', '#E52562 ', '#673D8B', '#6B7186'],
            trendsView: {
                yearRanges: [1, 3, 5],
                defaultRange: 3,
                getYearRangeString: (year) => year + (year > 1 ? ' years' : ' year')
            }
        };

        result.routesWithoutPermissions = [
            result.urls.cookiePolicy,
            result.urls.cookieList,
            result.urls.emailVerification,
            result.urls.forbidden,
            result.urls.internalError,
            result.urls.logout,
            result.urls.login            
        ];

        return result;
    }]);
